import React from 'react';
import styled from 'styled-components';
import HeroWithButton from './HeroWithButton';
import Quote from './Quote';
import ImageFrame from './ImageFrame';
import HeroSection from './HeroSection';
import MediaSection from './MediaSection';
import SubscribeSection from './SubscribeSection';
import TextBlock from './TextBlock';
import FloatingImagesSection from './FloatingImagesSection';
import { desktopBreakpoint, desktopVW, mobileVW } from '../../styles';
import ScrollGrid from './ScrollGrid';
import PublitasContainer from './PublitasContainer';
import FloatingImagesSectionTypeTwo from './FloatingImagesSectionTypeTwo';
import FeaturedItemSection from './FeaturedItemSection';
import AllProductsGrid from '../allProducts/AllProductsGrid';
import { useGlobalDictionaryQuery } from '../../hooks/utils/useGlobalDictionaryQuery';

const GeneralSection = ({ section, ignoreMargins, i }: any) => {
  const dictionary = useGlobalDictionaryQuery();

  // console.log("i", i);
  const forceMarginsOnFirstSection =
    section?.__typename == 'ContentfulSectionsGeneral' &&
    section?.type?.toLowerCase().includes('floating images') &&
    i === 0;
  return (
    <SectionWrapper
      ignoreMargins={
        ignoreMargins ||
        section?.type?.toLowerCase?.()?.includes?.('floating images type two') ||
        section?.type?.toLowerCase?.()?.includes?.('featured item')
      }
      first={i === 0}
      forceMargins={forceMarginsOnFirstSection}
    >
      {(() => {
        if (section.__typename == 'ContentfulSectionsGeneral') {
          if (section.type.toLowerCase().includes('form')) {
            if (section.form.toLowerCase() == 'subscribe fullscreen') {
              return (
                <SubscribeSection
                  title={section.text}
                  heroImage={dictionary.imageForForm}
                  heroTopText={section.heroTopText}
                  heroBottomText={section.heroBottomText}
                />
              );
            }
          }
          if (section.type.toLowerCase().includes('text')) {
            return (
              <TextBlock
                textBlock1={section.textBlockColumn1}
                textBlock2={section.textBlockColumn2}
                hideDoodle={section.textBlockHideDoodle}
              />
            );
          }
          if (section.type.toLowerCase().includes('floating images type two')) {
            return (
              <FloatingImagesSectionTypeTwo
                heading={section.floatingImagesHeading}
                paragraph={section.floatingImagesParagraph}
                images={section.floatingImagesImages}
              />
            );
          }
          if (section.type.toLowerCase().includes('floating images')) {
            return (
              <FloatingImagesSection
                heading={section.floatingImagesHeading}
                paragraph={section.floatingImagesParagraph}
                images={section.floatingImagesImages}
              />
            );
          }
          if (section.type.toLowerCase().includes('featured item')) {
            return (
              <FeaturedItemSection
                heading={section.featuredItemTitle}
                subtitle={section.featuredItemSubtitle.featuredItemSubtitle}
                paragraph={section.featuredItemParagraph.featuredItemParagraph}
                items={section.featuredItemItems}
              />
            );
          }
          if (section.type.toLowerCase().includes('products')) {
            return (
              <AllProductsGrid
                items={section.products}
                isFixedCategory
                category={section.title}
                button={{
                  text: section.heroButtonButtonText,
                  link: section.heroButtonLink,
                }}
              />
            );
          }
          if (section.type.toLowerCase().includes('hero button')) {
            return (
              <HeroWithButton
                heroImage={section.heroButtonHeroImage}
                link={section.heroButtonLink}
                heading={section.heroButtonHeading}
                buttonText={section.heroButtonButtonText}
              />
            );
          }
          if (section.type.toLowerCase().includes('image frame')) {
            return <ImageFrame image={section.imageFrameImage} text={section.imageFrameText} />;
          }
          if (section.type.toLowerCase().includes('quote')) {
            return <Quote text={section.quoteText} />;
          }
          if (section.type.toLowerCase().includes('scrollable grid')) {
            return (
              <ScrollGrid
                paragraph1={section.scrollableGridParagraph1}
                paragraph2={section.scrollableGridParagraph2}
                image1={section.scrollableGridImage1}
                image2={section.scrollableGridImage2}
              />
            );
          }
          if (section.type.toLowerCase().includes('publitas')) {
            return <PublitasContainer first={i === 0} />;
          }
          if (section.type.toLowerCase().includes('group')) {
            return section.sectionsGroupSections.map(subSection =>
              GeneralSection({ section: subSection, ignoreMargins: true })
            );
          }
        }
        if (section.__typename == 'ContentfulMediaSection') {
          return <MediaSection defaultMargins={false} mediaSection={section} />;
        }
      })()}
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div<any>`
  margin: ${({ ignoreMargins, first, forceMargins }) =>
      ignoreMargins || first ? (forceMargins ? `${mobileVW(80)} 0 0` : 0) : mobileVW(50)}
    0;
  ${desktopBreakpoint} {
    margin: ${({ ignoreMargins, first, forceMargins }) =>
        ignoreMargins || first ? (forceMargins ? `${desktopVW(120)} 0 0` : 0) : desktopVW(90)}
      0;
  }
`;

export default GeneralSection;
