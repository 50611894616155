import React from "react";
import styled from "styled-components";
import {
  desktopBreakpoint,
  colors,
  desktopVW,
  mobileVW,
  zIndex,
  mobileContentSidePadding,
} from "../../styles/index";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { useGlobalDictionaryQuery } from "../../hooks/index";
import { useLocale } from "../../hooks/layout/useLocale";

interface Props {
  heroImage: {
    fluid: string;
  };
  link: string;
  isSingleHeading?: boolean;
  buttonText: string;
  heading: string;
}

export default function HeroWithButton(props: Props) {
  const {
    heroImage,
    link,
    isSingleHeading,
    buttonText,
    heading
  } = props;

  // console.log(link);

  const dictionary = useGlobalDictionaryQuery();

  const currLocale = useLocale();

  return (
    <>
      <Wrapper>
        <AbsoluteWrapper>
          <StyledImg fluid={heroImage.fluid} loading="lazy" />
        </AbsoluteWrapper>
        <Heading>{heading}</Heading>

        <Button to={`/${currLocale}/${link?.target?.slug}/`}>{buttonText}</Button>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h5 {
    z-index: ${zIndex.surface};
    font-family: "messina";
    font-size: ${mobileVW(40)};
    color: white;
    line-height: ${mobileVW(45)};
    font-weight: 400;

    ${desktopBreakpoint} {
      font-size: ${desktopVW(170)};
      padding-bottom: ${desktopVW(18)};
      line-height: ${desktopVW(153)};
    }
  }

  ${desktopBreakpoint} {
    height: 116vh;
  }
`;

const Heading = styled.h5`
  text-align: center;
  padding: 0 ${mobileVW(27)};
  font-size: ${mobileVW(50)} !important;
  ${desktopBreakpoint} {
    font-size: ${desktopVW(140)} !important;
    padding: 0 ${desktopVW(200)};
  }
`

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
`;

const AbsoluteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.backgrounds};
`;

const Button = styled(Link)`
  z-index: ${zIndex.surface};
  background-color: white;
  font-family: "messinaMonoLight";
  letter-spacing: -0.05em;
  color: ${colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: ${mobileVW(30)};
  left: 50vw;
  transform: translateX(-50%);
  width: ${mobileVW(330)};
  height: ${mobileVW(80)};
  transition-duration: 0.25s;

  &:hover {
    ${desktopBreakpoint} {
      color: ${colors.white};
      background-color: ${colors.black};
    }
  }

  ${desktopBreakpoint} {
    position: relative;
    bottom: auto;
    left: auto;
    width: ${desktopVW(300)};
    height: ${desktopVW(77)};
    transform: translateX(0%);
    font-size: ${desktopVW(14)};
  }
`;
