import React from 'react';
import styled from 'styled-components';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../styles';
import Img from 'gatsby-image'

const ScrollGrid = ({ paragraph1, paragraph2, image1, image2 }) => {
  // console.log("TEST", paragraph1, image1);
  return (
    <ScrollGridWrapper>
      <ScrollableElement>
        {paragraph1 && image1 &&
          <HorizontalGridElement>
            <GridHorizontalParagraphWrapper>
            {paragraph1?.json &&
              paragraph1.json.content.map((textContent) => {
                if (textContent.nodeType == "paragraph") return <GridParagraph>{textContent.content.flatMap((textNode) => textNode.nodeType == "text" ? textNode.value : false)}</GridParagraph>;
              })
            }
            </GridHorizontalParagraphWrapper>
            <StyledImage
              fluid={image1.fluid}
            />
          </HorizontalGridElement>
        }
        {paragraph2 && image2 &&
          <VerticalGridElement>
          <GridVerticalParagraphWrapper>
          {paragraph2?.json &&
            paragraph2.json.content.map((textContent) => {
              if (textContent.nodeType == "paragraph") return <GridParagraph>{textContent.content.flatMap((textNode) => textNode.nodeType == "text" ? textNode.value : false)}</GridParagraph>;
            })
          }
          </GridVerticalParagraphWrapper>
          <StyledImage
            fluid={image2.fluid}
          />
        </VerticalGridElement>
        }
      </ScrollableElement>
    </ScrollGridWrapper>
  );
};

const ScrollGridWrapper = styled.div`
  padding-left: ${desktopVW(1)};
  overflow: scroll hidden;
  overscroll-behavior: unset !important;
  width: 100%;
  display: flex;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  z-index: 9999;
  ::-webkit-scrollbar {
    display: none;
  }
  ${desktopBreakpoint} {
    overflow: hidden;
  }
`

const ScrollableElement = styled.div`
  display: flex;
  padding: 0 ${mobileVW(40)};
  overscroll-behavior: unset !important;

  ${desktopBreakpoint} {
    padding: 0 ${desktopVW(150)};
    width: 100%;
  }
`

const GridHorizontalParagraphWrapper = styled.div`
  padding: 0 ${desktopVW(40)} 0 0;
  width: ${mobileVW(200)};
  margin-right: ${mobileVW(30)};
  overscroll-behavior: unset !important;
  *{
    overscroll-behavior: unset !important;
  }
  ${desktopBreakpoint} {
    margin-right: 0;
    width: ${desktopVW(300)};
  }
`

const GridVerticalParagraphWrapper = styled.div`
  padding: ${desktopVW(50)} 0 0 0;
  width: ${mobileVW(350)};
  *{
    overscroll-behavior: unset !important;
  }
  overscroll-behavior: unset !important;
  ${desktopBreakpoint} {
    width: ${desktopVW(270)};
  }
`

const StyledImage = styled(Img)`
  width: ${mobileVW(350)};
  overscroll-behavior: unset !important;
  *{
    overscroll-behavior: unset !important;
  }
  ${desktopBreakpoint} {
    width: ${desktopVW(470)};
  }
`

const HorizontalGridElement = styled.div`
  display: flex;
  overscroll-behavior: unset !important;
`

const VerticalGridElement = styled.div`
  display: flex;
  overscroll-behavior: unset !important;
  margin-left: ${mobileVW(30)};
  flex-direction: column-reverse;
  ${desktopBreakpoint} {
    margin-left: ${desktopVW(50)};
  }
`

const GridParagraph = styled.div`
  font-family: "messinaMonoLight";
  letter-spacing: -0.05em;
  font-weight: 300;
  color: ${colors.subtleGrey};
  font-size: ${mobileVW(16)};
  ${desktopBreakpoint}{
    font-size: ${desktopVW(14.5)}; 
    line-height: ${desktopVW(21)};
  }
`

export default ScrollGrid;