/* eslint-disable */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  desktopBreakpoint,
  colors,
  desktopVW,
  mobileVW,
  zIndex,
  mobileContentSidePadding,
} from '../../styles/index';
import Img from 'gatsby-image';
import Icons from '../../utils/Icons';
import { useGlobalComponentsQuery, useGlobalDictionaryQuery } from '../../hooks';
import { useState } from 'react';
import { subscribe } from 'klaviyo-subscribe';
import InputForm from '../account/accountForms/InputForm';

interface Props {
  heroImage?: {
    fluid: string;
  };
  title?: string;
  heroTopText?: string;
  heroBottomText?: string;
}

export default function HeroSection(props: Props) {
  const { heroImage, title, heroTopText, heroBottomText } = props;

  const dictionary = useGlobalDictionaryQuery();

  const global = useGlobalComponentsQuery();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [containErrors, SetContainErrors] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [pageOutlet, setPageOutlet] = useState(false);

  const handleContainerrors = state => {
    if (state) {
      SetContainErrors(true);
    }
    if (!state) {
      SetContainErrors(false);
    }
  };

  const reset = () => {
    setClicked(false);
    setLoading(false);
    setEmail('');
    setFirstName('');
    setLastName('');
  };

  useEffect(() => {
    let currentUrl = window.location.href;
    if (currentUrl.includes('outlet')) {
      setPageOutlet(true);
    } else {
      setPageOutlet(false);
    }
  }, []);

  const submitForm = async e => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      subscribe(global?.klaviyoNewsletterListId, email, {
        first_name: firstName,
        last_name: lastName,
      }).then(response => {
        setClicked(true);
        if (response.success) {
          if (response.data.is_subscribed) {
            setText(dictionary?.emailSubscribeAlreadyInListMsg);
          } else {
            setText(dictionary?.emailSubscribeSuccessMsg);
          }
          setTimeout(() => reset(), 3000);
        } else {
          setText(dictionary?.emailSubscribeFailMsg);
          setTimeout(() => reset(), 3000);
        }
      });
    }, 500);
  };
  const submitFormOutlet = async e => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      subscribe(global.klaviyoOutletEventListId, email, {
        first_name: firstName,
        last_name: lastName,
      }).then(response => {
        setClicked(true);
        if (response.success) {
          if (response.data.is_subscribed) {
            setText(dictionary?.emailSubscribeAlreadyInListMsg);
          } else {
            setText(dictionary?.emailSubscribeSuccessMsg);
          }
          setTimeout(() => reset(), 3000);
        } else {
          setText(dictionary?.emailSubscribeFailMsg);
          setTimeout(() => reset(), 3000);
        }
      });
    }, 500);
  };

  return (
    <Wrapper>
      {heroImage && <StyledImg fluid={heroImage.fluid} loading="eager" fadeIn={true} />}
      <Form>
        <FormContainer>
          <FormTitle style={{ gridArea: 'title' }}>
            <PreTitle>
              {pageOutlet ? dictionary.informationFormOutlet : dictionary.stayInformed}
            </PreTitle>
            {pageOutlet ? dictionary.titleFormOutlet : dictionary.subscribe}
          </FormTitle>
          <InputForm
            style={{ gridArea: 'firstName' }}
            inputValue={firstName}
            inputSetValue={setFirstName}
            inputName="firstName"
            inputType="text"
            required={true}
            onChangecallback={e => setFirstName(e.target.value)}
            placeholder={dictionary.firstName}
            color={colors.black}
            width={'100%'}
            widthDesk={'100%'}
            wrapperWidth={'100%'}
            wrapperWidthDesk={'95%'}
            fontSizeDesk={desktopVW(13)}
            fontSize={mobileVW(12)}
            validation={'onlyLetters'}
            setContainErrors={e => console.log(e)}
          />
          <InputForm
            style={{ gridArea: 'lastName', justifySelf: 'end' }}
            inputValue={lastName}
            inputSetValue={setLastName}
            inputName="lastName"
            inputType="text"
            required={true}
            onChangecallback={e => setLastName(e.target.value)}
            placeholder={dictionary.lastName}
            color={colors.black}
            width={'100%'}
            widthDesk={'100%'}
            wrapperWidth={'100%'}
            wrapperWidthDesk={'95%'}
            fontSizeDesk={desktopVW(13)}
            fontSize={mobileVW(12)}
            validation={'onlyLetters'}
            setContainErrors={e => console.log(e)}
          />
          <InputForm
            inputValue={email}
            style={{ gridArea: 'email' }}
            inputSetValue={setEmail}
            inputName="email"
            inputType="text"
            required={true}
            onChangecallback={e => setEmail(e.target.value)}
            placeholder={dictionary.emailAddress}
            color={colors.black}
            width={'100%'}
            widthDesk={'100%'}
            wrapperWidth={'100%'}
            fontSizeDesk={desktopVW(13)}
            fontSize={mobileVW(12)}
            validation={'onlyEmail'}
            setContainErrors={e => console.log(e)}
          />
          <FormButton
            style={{ gridArea: 'button' }}
            onClick={pageOutlet ? submitFormOutlet : submitForm}
          >
            {' '}
            {pageOutlet
              ? dictionary.textButtonFormOutlet
              : dictionary.newsletterSubscribeButtonText}
          </FormButton>
          {text && <FormMessage style={{ gridArea: 'message' }}>{text}</FormMessage>}
        </FormContainer>
      </Form>
    </Wrapper>
  );
}

const CustomInputForm = styled(InputForm)`
  width: 100% !important;
  ${desktopBreakpoint} {
    width: 45% !important;
  }
`;
const PreTitle = styled.p`
  font-family: 'messinaMonoLight';
  font-size: ${mobileVW(12)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
  }
`;

const Wrapper = styled.section`
  width: 100vw;
  overflow: hidden;
  height: 100vh;
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: visible;
  ${desktopBreakpoint} {
    height: 100vh;
    flex-direction: row;
    align-items: flex-start;
  }
`;

const FormMessage = styled.div`
  min-height: ${desktopVW(20)};
`;

const Form = styled.form`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      //height: ${desktopVW(375)};
      background: ${colors.frappuccino};
      z-index: 20;
      margin: 0;
      font-family: "messinaMono";
      ${desktopBreakpoint}{
        width: ${desktopVW(640)};
        height: ${desktopVW(430)};
        margin: 40vh 17vw 0 0;
      }
      `;

const FormContainer = styled.div`
  margin: ${mobileVW(30)} 0;
  width: ${mobileVW(300)};
  display: grid;
  grid-template-columns: ${mobileVW(150)} ${mobileVW(150)};
  grid-template-rows: auto auto auto auto auto auto;
  grid-gap: ${mobileVW(10)};
  grid-template-areas:
    'title title'
    'firstName firstName'
    'lastName lastName'
    'email email'
    'button button'
    'message message';
  ${desktopBreakpoint} {
    margin: 0 0;
    margin-top: 10px;
    grid-template-columns: ${desktopVW(265)} ${desktopVW(265)};
    width: 100%;
    padding: 0 ${desktopVW(50)};
    grid-gap: ${desktopVW(17)};
    grid-template-areas:
      'title title'
      'firstName lastName'
      'email email'
      'button button'
      'message message';
  }
`;

const FormTitle = styled.h2`
  font-size: ${mobileVW(26)};
  font-weight: 200;
  ${desktopBreakpoint} {
    font-size: ${desktopVW(26)};
  }
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${desktopVW(20)} 0;
`;

const FormInputStyle = styled.input`
  border: 1px solid ${colors.cappuccino};
  font-family: 'messinaMono';
  height: ${desktopVW(60)};
`;

const HalfFormInput = styled(FormInputStyle)`
  width: 45%;
`;

const FormInput = styled(FormInputStyle)`
  width: 100%;
`;

const FormButton = styled.button`
  text-align: center;
  width: 100%;
  font-family: 'messinaMono';
  font-size: ${mobileVW(12)};
  height: ${mobileVW(60)};
  background-color: ${colors.black};
  color: white;
  transition: 0.3s;
  :hover {
    cursor: pointer;
    background-color: ${colors.blackButABitDarker};
  }
  ${desktopBreakpoint} {
    font-size: ${desktopVW(13)};
    height: ${desktopVW(60)};
  }
`;

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
  z-index: ${zIndex.backgrounds};

  ${desktopBreakpoint} {
    position: absolute !important;
  }
`;

const TitleTop = styled.h1`
  z-index: ${zIndex.surface};
  position: absolute;
  text-transform: uppercase;
  font-family: 'messina', Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: white;
  top: 45vh;
  left: ${mobileContentSidePadding};
  font-size: ${({ char }) => (char > 7 ? `${mobileVW(43)}` : `${mobileVW(62)}`)};
  transform: translateY(-100%);

  ${desktopBreakpoint} {
    top: ${desktopVW(110)};
    left: ${desktopVW(106)};
    font-size: ${desktopVW(120)};
    transform: translateY(0%);
  }
`;

const TitleBottom = styled.h1`
  z-index: ${zIndex.surface};
  position: absolute;
  text-transform: uppercase;
  font-family: 'messina', Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: white;
  bottom: 55vh;
  right: ${mobileContentSidePadding};
  font-size: ${({ char }) => (char > 7 ? `${mobileVW(43)}` : `${mobileVW(62)}`)};
  transform: translateY(100%);

  ${desktopBreakpoint} {
    bottom: ${desktopVW(121)};
    right: ${desktopVW(106)};
    font-size: ${desktopVW(120)};
    transform: translateY(0%);
  }
`;

const TitleBottomContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  ${desktopBreakpoint} {
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: ${mobileVW(10)};
  left: 0;
  right: auto;

  ${desktopBreakpoint} {
    left: auto;
    right: ${desktopVW(-50)};
    top: ${desktopVW(-10)};
  }
`;
