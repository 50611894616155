/* eslint-disable  */

/* eslint-disable-line no-console  */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { animated, useSpring, config } from 'react-spring';
import { navigate } from 'gatsby';
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex } from '../../styles/index';
import { SettingsContext } from '../../contexts';
import Icons from '../../utils/Icons';
import { useGlobalDictionaryQuery } from '../../hooks/utils/useGlobalDictionaryQuery';
import { useLocale } from '../../hooks';

interface Props {
  allProductsCategories?: [];
  category?: string;
  isFixedCategory?: boolean;
}

export default function allProductsHeader(props: Props) {
  const { allProductsCategories, category, isFixedCategory, pageProductCategory } = props;

  const {
    isFilterOpen,
    setFilterOpen,
    categoryFilter,
    setCategoryFilter,
    roomFilter,
    collectionFilter,
    filtersCount,
    renderTopBar,
    menuShop,
  }: {
    isFilterOpen: any;
    setFilterOpen: any;
    categoryFilter: any;
    setCategoryFilter: any;
    roomFilter: any;
    collectionFilter: any;
    renderTopBar: any;
    menuShop: any;
    filtersCount: () => number;
  } = useContext(SettingsContext);

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuVisibility, setMenuVisibility] = useState(false);
  const dictionary = useGlobalDictionaryQuery();

  const categoryMenu = [dictionary.allProducts, ...(allProductsCategories?.sort?.() || [])];

  const categoriesMenuSpring = useSpring({
    transform: menuOpen ? 'translateY(0%)' : 'translateY(-40%)',
    opacity: menuOpen ? 1 : 0,
    display: menuOpen ? 'flex' : 'none',
    config: {
      tension: 152,
      friction: 25,
    },
    onRest: () => !menuOpen && setMenuVisibility(false),
  });

  const locale = useLocale();

  useEffect(() => {
    if (menuOpen) setMenuVisibility(true);
  }, [menuOpen]);

  useEffect(() => {
    if (pageProductCategory) {
      setCategoryFilter([pageProductCategory]);
    } else {
      setCategoryFilter([]);
    }
  }, [pageProductCategory]);

  return (
    <Wrapper style={{ marginTop: renderTopBar ? 70 : 0 }} isFixedHeight={!isFixedCategory}>
      <HeaderBar>
        {allProductsCategories ? (
          <>
            <CategoryFilterWrapper onMouseLeave={() => setMenuOpen(false)}>
              <Overlay />
              <CategoryFilterValue
                type="button"
                aria-label="show category filters"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                {categoryFilter && categoryFilter.length === 0
                  ? dictionary.allProducts?.replace('-', ' ')
                  : categoryFilter?.includes('Newest')
                  ? locale === 'en'
                    ? 'New products'
                    : 'Nieuwe producten'
                  : categoryFilter}

                <IconWrapper>
                  <Icons name={menuOpen ? 'arrowUp' : 'arrowDown'} />
                </IconWrapper>
              </CategoryFilterValue>
              <CategoriesMenuWrapper style={{ ...categoriesMenuSpring }}>
                {menuShop?.menus?.map((item: any) => {
                  let slug: string = '';
                  if (item.__typename === 'ContentfulComponentLink') {
                    slug = `/${locale}/${item.target.slug}`;
                  } else {
                    slug = `/${locale}/${item.title
                      .replace('New products ', 'newest')
                      .replace('New products', 'newest')
                      .replace('Nieuwe producten', 'newest')
                      .replace('&', '-and-')
                      .replace(' ', '-')
                      .replace('--', '-')
                      .replace(/ /g, '')
                      .toLowerCase()}`;
                  }

                  return (
                    <CategoriesMenu
                      key={item?.title}
                      onClick={() => {
                        navigate(slug);
                        setMenuOpen(false);
                      }}
                    >
                      {item.title}
                    </CategoriesMenu>
                  );
                })}
              </CategoriesMenuWrapper>
            </CategoryFilterWrapper>
            <RelativeWrapper>
              {filtersCount > 0 && <FilterCount>{filtersCount}</FilterCount>}
              <FilterButton
                type="button"
                aria-label="open filters"
                onClick={() => setFilterOpen(true)}
              >
                Filters
              </FilterButton>
            </RelativeWrapper>
          </>
        ) : (
          <CategoryFilterWrapper>
            <CategoryFilterValue type="button" aria-label="show category filters">
              {category}
            </CategoryFilterValue>
          </CategoryFilterWrapper>
        )}
      </HeaderBar>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: ${({ isFixedHeight }) => (isFixedHeight ? desktopVW(200) : `auto`)};
  position: relative;
  @media (max-width: 1024px) {
    padding-top: 400px;
  }
  @media (max-width: 768px) {
    padding-top: 350px;
  }
  @media (max-width: 540px) {
    padding-top: 250px;
  }
  @media (max-width: 450px) {
    padding-top: 190px;
  }
  @media (max-width: 410px) {
    padding-top: 170px;
  }
  @media (max-width: 300px) {
    padding-top: 130px;
  }

  ${desktopBreakpoint} {
    height: ${({ isFixedHeight }) => (isFixedHeight ? desktopVW(200) : `auto`)};
  }
`;

const HeaderBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: ${zIndex.surface + 2};

  ${desktopBreakpoint} {
    padding: 0 ${desktopVW(60)};
    bottom: ${desktopVW(10)};
    flex-direction: row;
    justify-content: space-between;
  }
`;

const CategoryFilterWrapper = styled.div`
  ${desktopBreakpoint} {
    padding-bottom: ${desktopVW(28)};
  }
`;

const CategoryFilterValue = styled.button`
  position: relative;
  font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
  font-size: ${mobileVW(25)};
  letter-spacing: -0.05em;
  z-index: ${zIndex.surface + 3};
  cursor: pointer;
  text-align: left;
  display: flex;
  flex-direction: row;

  ${desktopBreakpoint} {
    font-size: ${desktopVW(31)};
  }
`;

const CategoriesMenuWrapper = styled(animated.div)<any>`
  position: absolute;
  top: ${mobileVW(45)};
  left: 0;
  width: 100vw;
  background-color: ${colors.black};
  //display: flex;
  flex-direction: column;
  z-index: ${zIndex.surface + 1};
  will-change: transform;
  text-align: center;
  display: ${({ show }) => (show ? `flex` : `none`)};

  ${desktopBreakpoint} {
    width: auto;
    top: ${desktopVW(45)};
    left: ${desktopVW(60)};
    text-align: left;
  }
`;

const CategoriesMenu = styled.div`
  font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  color: ${colors.white};
  border-bottom: ${mobileVW(1)} solid ${colors.subtleGrey};
  cursor: pointer;
  user-select: none;
  font-size: ${mobileVW(20)};
  padding: ${mobileVW(20)};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(20)};
    padding: ${desktopVW(20)} ${desktopVW(30)};
    border-bottom: ${desktopVW(1)} solid ${colors.subtleGrey};
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: ${mobileVW(-120)};
  left: 0;
  width: 100vw;
  height: ${mobileVW(165)};
  background-color: ${colors.frappuccino};
  z-index: ${zIndex.surface + 2};

  ${desktopBreakpoint} {
    top: ${desktopVW(-140)};
    left: ${desktopVW(60)};
    width: ${desktopVW(300)};
    height: ${desktopVW(185)};
  }
`;

const IconWrapper = styled.div``;

const FilterButton = styled.button`
  font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  border: ${mobileVW(1)} solid ${colors.black};
  margin-top: ${mobileVW(30)};
  padding: ${mobileVW(13)} ${mobileVW(21)};
  font-size: ${mobileVW(15)};

  ${desktopBreakpoint} {
    border: ${desktopVW(1)} solid ${colors.black};
    padding: ${desktopVW(13)} ${desktopVW(21)};
    font-size: ${desktopVW(15)};
    margin-bottom: ${desktopVW(20)};
    margin-top: 0;
  }
`;

const RelativeWrapper = styled.div`
  position: relative;
`;

const FilterCount = styled.div`
  position: absolute;
  color: ${colors.frappuccino};
  background-color: ${colors.black};
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: ${mobileVW(33)};
  right: ${mobileVW(3)};
  height: ${mobileVW(20)};
  width: ${mobileVW(20)};
  font-size: ${mobileVW(11)};

  ${desktopBreakpoint} {
    top: ${desktopVW(10)};
    right: ${desktopVW(10)};
    width: ${desktopVW(13)};
    height: ${desktopVW(13)};
    font-size: ${desktopVW(8)};
  }
`;
