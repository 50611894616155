import React from 'react';
import styled from 'styled-components';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../styles';
import Icons from '../../utils/Icons';

const Quote = ({ text }) => {
  // console.log(text);

  return (
    <QuoteWrapper>

      <LogoWrapper>
        <Icons
          name="logoBlack"
        />
      </LogoWrapper>

      <QuoteTextWrapper>
        <IconWrapper>
          <Icons
            color={colors.black}
            name="doodle1"
          />
        </IconWrapper>
        {text.json.content.map((node) =>
          <QuoteTextLine>
            {node.content.map((text) => {
              if (text.marks.length == 0) return text.value;
              if (text.marks.some((mark) => mark.type == "underline")) return <u>{text.value}</u>
            })
            }
          </QuoteTextLine>
        )}
      </QuoteTextWrapper>
    </QuoteWrapper>
  );
};

const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${mobileVW(40)};
  //margin: ${mobileVW(70)} 0;
  ${desktopBreakpoint} {
    padding: ${desktopVW(70)};
    //margin: ${desktopVW(120)} 0;
  }
`

const IconWrapper = styled.div`
  position: absolute;
`

const QuoteTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const LogoWrapper = styled.div`
  margin-bottom: ${mobileVW(50)};
  ${desktopBreakpoint} {
    margin-bottom: ${desktopVW(40)};
  }
`

const QuoteTextLine = styled.h1`
  font-family: "messinamono";
  font-weight: 200;
  font-size: ${mobileVW(25)};
  color: ${colors.black}
  ${desktopBreakpoint} {
    font-size: ${desktopVW(60)};
  }
`

export default Quote;