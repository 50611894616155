import React from 'react';
import styled from 'styled-components';
import { desktopBreakpoint, colors, desktopVW, mobileVW } from '../../styles/index';
import Icons from '../../utils/Icons';

interface Props {
  starsQuantity: number;
  totalStars: number;
}

export default function StarsReview(props: Props) {
  const { starsQuantity, totalStars } = props;
  const stars = Array(starsQuantity).fill('potato');
  return (
    <Wrapper>
      {stars.map((item: any, i: number) => {
        if (i < totalStars) {
          return <Icons name="starFilled" key={i} />;
        } else {
          return <Icons name="starEmpty" key={i} />;
        }
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${desktopBreakpoint} {
    width: ${desktopVW(90)};
  }
`;
