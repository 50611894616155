import { graphql } from 'gatsby';
import React from 'react';

import SEO from '../components/shared/SEO';
import { useGlobalComponentsQuery } from '../hooks';
import Freckles from '../components/shared/FrecklesOverlappable'
import styled from 'styled-components';
import { zIndex } from '../styles';

import GeneralSection from '../components/sections/GeneralSection';

const General = ({ data }) => {
  const page = data.contentfulPageGeneral;
  const sections = data.contentfulPageGeneral.sections;
  const global = useGlobalComponentsQuery();

  // console.log(page, sections);
  return (
    <>
{/*       <FrecklesWrapper>
        <Freckles
          canvasHeight={'100vh'}
        />
      </FrecklesWrapper> */}
      <SEO
        title={page.seoTitle}
        description={page.seoDescription}
        image={global?.seoImage.file.url}
      />
      {sections.map((section, i) => (
        <FrecklesWrapper>
          <CustomFreckles />
          <GeneralSection section={section} i={i}/>
          </FrecklesWrapper>
      )
      )
      }
    </>
  )
};

const CustomFreckles = styled(Freckles)`
  position: absolute;
  top: 0;
  min-height: 100vh;
  height: 100%;
  z-index: -1;
`

const FrecklesWrapper = styled.div`
  z-index: ${zIndex.dots};
  position: relative;
  will-change: transform;
  overflow: hidden;
`;

export default General;

const query = `

`

export const PageGeneralQuery = graphql`
  query PageGeneral($id: String!) {
    contentfulPageGeneral(id: { eq: $id }) {
      slug
      title
      seoTitle
      seoDescription
      sections {
        ... on ContentfulSectionsGeneral {
          ...contentfulSectionsGeneral
        }
        ... on ContentfulMediaSection {
          ...contentfulMediaSection
        }
      }
    }
  }
`