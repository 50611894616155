import React from 'react';
import styled from 'styled-components';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../styles';
import Icons from '../../utils/Icons';
import RichText from '../shared/RichText';

const TextBlock = ({ textBlock1, textBlock2, hideDoodle }) => {
  return (
  <TextBlockContainer additionalPadding={textBlock2?.json}>
    {textBlock1?.json && (
      <TextColumn1 alone={!textBlock2?.json}>
        <RichText json={textBlock1.json} />
      </TextColumn1>
    )}
    {textBlock2?.json && (
      <>
        {!hideDoodle && (
          <IconContainer>
            <Icons color={colors.black} name="doodle1" />
          </IconContainer>
        )}
        <TextColumn2>{textBlock2.json && <RichText json={textBlock2.json} />}</TextColumn2>
      </>
    )}
  </TextBlockContainer>
);}

const TextBlockContainer = styled.section<any>`
  font-family: "MessinaMono";
  font-size: ${mobileVW(15)};
  font-weight: 200;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: ${colors.subtleGrey};
  margin: 0 ${mobileVW(30)};
  //margin: ${mobileVW(20)} 0;

  p {
    white-space: break-spaces;
  }
  ${desktopBreakpoint} {
    font-size: ${desktopVW(15)};
    //margin: ${desktopVW(100)} 0;
    align-items: flex-start;
    padding: ${({ additionalPadding }) => (additionalPadding ? desktopVW(50) : 0)} 0 0 0;
    flex-direction: row;
  }
  h1 {
    margin: 20px auto 37px;
    color: ${colors.black};
    text-transform: uppercase;
    font-family: "messina";
    font-weight: 400;
    font-size: ${mobileVW(40)};
    line-height: ${mobileVW(36)};
    width: ${mobileVW(330)};
    ${desktopBreakpoint} {
      width: ${desktopVW(500)};
      font-size: ${desktopVW(80)};
      line-height: ${desktopVW(72)};
    }
  }
`;

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin: ${mobileVW(60)};
  ${desktopBreakpoint} {
    margin: 0;
  }
`;

const TextColumn1 = styled(TextColumn)<any>`
  margin-bottom: ${({ alone }) => (alone ? 0 : mobileVW(60))};
  ${desktopBreakpoint} {
    margin: 0 !important;
    p {
      width: ${({ alone }) => (alone ? desktopVW(640) : desktopVW(500))} !important;
    }
  }
`;
const TextColumn2 = styled(TextColumn)`
  margin-top: ${mobileVW(60)};
  ${desktopBreakpoint} {
    margin: 0 !important;
    p {
      width: ${desktopVW(500)} !important;
    }
  }
`;

const IconContainer = styled.div`
  margin-bottom: -${mobileVW(80)};
  ${desktopBreakpoint} {
    margin: 0;
    position: absolute;
    top: -10%;
  }
`;

export default TextBlock;
