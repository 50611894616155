import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex } from '../../styles/index';
import RichText from '../shared/RichText';
import Icons from '../../utils/Icons';

interface Props {
  heading: {
    json: string;
  };
  paragraph: {
    paragraph: string;
  };
  images: {
    fluid: string;
  };
}

export default function FloatingImagesSectionTypeTwo(props: Props) {
  const { heading, paragraph, images } = props;

  return (
    <Wrapper>
      <TopRightImg>
        <StyledImg fluid={images[0].fluid} />
      </TopRightImg>
      <MidLeftImg>
        <StyledImg fluid={images[1].fluid} />
      </MidLeftImg>
      <BottomRightImg>
        <StyledImg fluid={images[2].fluid} />
      </BottomRightImg>
      <Text>
        <Heading>
          <RichText json={heading.json} />
        </Heading>
        <Paragraph>{paragraph.floatingImagesParagraph}</Paragraph>
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-bottom: ${mobileVW(350)};

  ${desktopBreakpoint} {
    min-height: 115vh;
    padding-bottom: 0;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${desktopBreakpoint} {
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: ${mobileVW(300)};
  z-index: ${zIndex.surface};

  h1 {
    text-transform: uppercase;
    font-family: 'messina';
    font-weight: 300;
    line-height: 90%;
    font-size: ${mobileVW(42)};
    color: ${colors.black};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(120)};
    }
  }
  ${desktopBreakpoint} {
    padding-top: ${desktopVW(341)};
  }
`;

const Paragraph = styled.div`
  width: 80%;
  font-family: 'messinaMono';
  line-height: 140%;
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  text-align: center;
  padding-top: ${mobileVW(40)};
  z-index: ${zIndex.surface};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(14.5)};
    width: 30%;
    padding-top: ${desktopVW(60)};
    padding-bottom: ${desktopVW(75)}
  }
`;

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
`;

const TopRightImg = styled.div`
  position: absolute;
  top: ${mobileVW(-10)};
  width: ${mobileVW(180)};
  height: ${mobileVW(262)};
  right: ${mobileVW(30)};
  ${desktopBreakpoint} {
    top: ${desktopVW(28)};
    right: ${desktopVW(103)};
    width: ${desktopVW(274)};
    height: ${desktopVW(399)};
  }
`;

const MidLeftImg = styled.div`
  position: absolute;
  top: ${mobileVW(50)};
  left: 0;
  height: ${mobileVW(202)};
  width: ${mobileVW(145)};

  ${desktopBreakpoint} {
    top: ${desktopVW(430)};
    left: 0;
    width: ${desktopVW(419)};
    height: ${desktopVW(553)};
  }
`;

const BottomRightImg = styled.div`
  position: absolute;
  bottom: ${mobileVW(30)};
  right: 0;
  width: ${mobileVW(370)};
  height: ${mobileVW(280)};

  ${desktopBreakpoint} {
    bottom: 0;
    right: 0;
    width: ${desktopVW(459)};
    height: ${desktopVW(321)};
  }
`;
