import React from 'react';
import styled from 'styled-components';
import { animated, useSpring, config } from 'react-spring';
import Img from 'gatsby-image';
import { useInView } from 'react-intersection-observer';
import {
  desktopBreakpoint,
  colors,
  desktopVW,
  mobileVW,
  SpacerDesktop,
  DesktopView,
  MobileView,
} from '../../styles/index';
import LinkTool from '../shared/LinkTool';
import formatPrice from '../../utils/formatPrice';
import { useGlobalComponentsQuery } from '../../hooks/index';
import { useLocale } from '../../hooks/layout/useLocale';

interface Props {
  title: string;
  slug: string;
  price: string;
  thumbnailImages: {
    fluid: string;
  };
  invertedImages: boolean;
  isFullScreen?: boolean;
  productTag: string;
  compareAtPrice: string;
  isMobileGrid: string;
  isBackgroundTransparent?: boolean;
}

export default function FeaturedItemThumbnail(props: Props) {
  const {
    title,
    slug,
    price,
    thumbnailImage,
    invertedImages,
    isFullScreen,
    productTag,
    compareAtPrice,
    isMobileGrid,
    isBackgroundTransparent,
  } = props;

  const locale = useLocale();

  return (
    <Wrapper hide={!thumbnailImage}>
      <Link to={`/${slug}/`}>
        <ImagesBlock invertedImages={invertedImages} isFullScreen={isFullScreen}>
          <ImgCropper>
            {thumbnailImage && <StyledImg fluid={thumbnailImage.fluid} loading="eager" />}
          </ImgCropper>
        </ImagesBlock>
        <TextBlock
          isMobileGrid={isMobileGrid}
          isFullScreen={isFullScreen}
          isBackgroundTransparent={isBackgroundTransparent}
        >
          <p className="thumbnailTitle">{title}</p>
        </TextBlock>
      </Link>
    </Wrapper>
  );
}

const Link = styled(LinkTool)`
  width: 100%;
  height: 100%;

  ${desktopBreakpoint} {
  }
`;

const Wrapper = styled.article`
  width: 100%;
  height: ${mobileVW(269)};
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: ${props => (props.hide ? 'none' : 'block')};
  ${desktopBreakpoint} {
    height: ${desktopVW(430)};
  }
`;

const ImagesBlock = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: ${({ invertedImages }) => (invertedImages ? 'row-reverse' : 'row')};
  width: 100%;
  height: 88%;

  ${desktopBreakpoint} {
    height: 92%;
  }
`;

const TextBlock = styled.div`
  width: 100%;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  padding-top: ${mobileVW(5)};
  padding-bottom: 0;
  height: ${mobileVW(45)};
  ${({ isMobileGrid }) => (isMobileGrid ? 'position: absolute;' : null)}
  bottom: 0;
  left: 0;
  background-color: ${({ isBackgroundTransparent }) =>
    isBackgroundTransparent ? 'transparent' : colors.frappuccino};

  .priceRow {
    display: flex;
    flex-direction: row;
  }

  p {
    color: ${colors.black};
    line-height: 150%;
    font-size: ${mobileVW(15)};
    font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
    letter-spacing: -0.05em;
    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
      padding-top: 0;
    }
  }

  ${desktopBreakpoint} {
    padding-top: ${desktopVW(0)};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 0;
    height: auto;
  }
`;

const StyledImg = styled(Img)`
  height: 100%;
  width: auto;

  ${desktopBreakpoint} {
  }
`;

const ImgCropper = styled(animated.div)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${colors.noItWasNotTheLastBeigeNouance};

  ${desktopBreakpoint} {
  }
`;
