import { navigate } from 'gatsby-link';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SettingsContext } from '../../contexts';
import { useLocale, useGlobalDictionaryQuery } from '../../hooks';
import { desktopBreakpoint, desktopVW, mobileVW, zIndex } from '../../styles/index';
import Button from '../shared/Button';
import ProductThumbnail from '../shared/ProductThumbnail';
import AllProductsHeader from './AllProductsHeader';

interface Props {
  items: {
    title: string;
    slug: string;
    shopifyData: {
      variants: {
        price: string;
        sku: string;
      };
    };
    thumbnailImages: {
      fluid: string;
    };
  };
  allProductsCategories?: [];
  isFixedCategory?: boolean;
  category?: string;
  productTag: string;
  pageProductCategory: string;
  button?: {
    text?: string;
    link?: {
      target: {
        slug: string;
      };
    };
  };
  videoThumbnailAtNumber3: string;
  contentThumbnailAtNumber3: string;
  videoThumbnailAtNumber3File: any;
}

export default function AllProductsGrid(props: Props) {
  const {
    items,
    allProductsCategories,
    category,
    isFixedCategory,
    button,
    pageProductCategory,
    videoThumbnailAtNumber3,
    contentThumbnailAtNumber3,
    videoThumbnailAtNumber3File,
  } = props;

  const {
    productAvailabelityFilter,
  }: {
    productAvailabelityFilter: any;
  } = useContext(SettingsContext);

  const locale = useLocale();

  const dictionary = useGlobalDictionaryQuery();
  return (
    <Wrapper>
      <AllProductsHeader
        allProductsCategories={allProductsCategories}
        isFixedCategory={isFixedCategory}
        category={category}
        pageProductCategory={pageProductCategory}
      />
      {items?.length === 0 && (
        <NoProductMessage>
          <p>Oups, no product matches with your search...</p>
        </NoProductMessage>
      )}

      <GridWrapper>
        {items?.map((item, index) => {
          const stock_variant = item.shopifyData.variants.filter(
            item => item.inventory_quantity != null && item.inventory_quantity > 0
          );
          return (
            <ThumbnailWrapper key={item.title + index}>
              <ProductThumbnail
                thumbnailVidio={dictionary.videoThumbnailAtNumber3}
                index={index}
                productId={item?.shopifyData?.variants[0]?.product_id}
                videoThumbnailAtNumber3={videoThumbnailAtNumber3}
                contentThumbnailAtNumber3={contentThumbnailAtNumber3}
                videoThumbnailAtNumber3File={videoThumbnailAtNumber3File}
                id={item.slug}
                pageAllProduct
                stock={stock_variant.length > 0}
                sku={item.shopifyData?.variants[0]?.sku}
                thumbnailImages={item.thumbnailImages}
                title={item.titleToShow}
                invertedImages={index === 2}
                price={item.shopifyData.variants[0].price}
                compareAtPrice={item.shopifyData.variants[0].compare_at_price}
                slug={item.slug}
                productTag={item.productTag}
                isFullScreen={index === 2}
                isMobileGrid="potato"
                variants={item.shopifyData.variants}
                thumbnailVariantsImages={item.thumbnailVariantsImages}
              />
            </ThumbnailWrapper>
          );
        })}
      </GridWrapper>
      {isFixedCategory && (
        <ButtonWrapper>
          <Button
            text={button?.text}
            Type=""
            widthDesk={desktopVW(300)}
            AriaLabel=""
            color="#FFF"
            colorBackground="#262525"
            callback={() => {
              navigate(`/${locale}/${button?.link?.target?.slug}`);
            }}
          />
        </ButtonWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  position: relative;
`;

const ButtonWrapper = styled.section`
  display: flex;
  justify-content: center;
  margin-top: ${desktopVW(52)};
`;

const GridWrapper = styled.div`
  display: grid;
  padding: ${mobileVW(35)} ${mobileVW(20)} 0 ${mobileVW(20)};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: ${mobileVW(20)};
  z-index: ${zIndex.surface};

  ${desktopBreakpoint} {
    grid-template-columns: repeat(4, ${desktopVW(310)});
    grid-template-rows: ${desktopVW(455)};
    grid-gap: ${desktopVW(26)};
    padding: 0 ${desktopVW(60)};
  }
`;

const ThumbnailWrapper = styled.div`
  height: ${mobileVW(310)};
  ${desktopBreakpoint} {
    height: ${desktopVW(455)};
  }
  &:nth-child(3) {
    grid-column: span 2;
    height: ${mobileVW(580)};
    ${desktopBreakpoint} {
      height: auto;
    }
  }
`;

const NoProductMessage = styled.div`
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-family: 'messinaMono';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(20)};
    width: 70%;
    text-align: center;
    ${desktopBreakpoint} {
      font-size: ${desktopVW(30)};
    }
  }
  ${desktopBreakpoint} {
    width: 100%;
    height: ${desktopVW(455)};
  }
`;
