import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex } from '../../styles/index'
import RichText from '../shared/RichText'
import Img from 'gatsby-image'
import Icons from '../../utils/Icons'

interface Props {
  heading: {
    json: string
  }
  paragraph: {
    paragraph: string
  }
  images: {
    fluid: string
  }
}

export default function FloatingImagesSection(props: Props) {

  const {
    heading,
    paragraph,
    images,
  } = props


  return (
    <Wrapper>
      <TopRightImg>
          <StyledImg
            fluid={images[0].fluid}
          />
      </TopRightImg>
      <MidLeftImg>
          <StyledImg
            fluid={images[1].fluid}
          />
      </MidLeftImg>
      <BottomLeftImg>
          <StyledImg
            fluid={images[2].fluid}
          />
      </BottomLeftImg>
      <Text>
        <Heading>
      <LogoWrapper>
        <Icons
          name="logoBlack"
          fullWidth={true}
        />
      </LogoWrapper>
          <RichText
            json={heading.json}
          />
        </Heading>
{/*         <Paragraph>
          {paragraph.floatingImagesParagraph}
        </Paragraph> */}
      </Text>
    </Wrapper>
  )
}

const LogoWrapper = styled.div`
  margin-bottom: ${mobileVW(50)};
  width: ${mobileVW(100)};
  ${desktopBreakpoint} {
    width: ${desktopVW(120)};
    margin-bottom: ${desktopVW(50)};
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-bottom: ${mobileVW(350)};
  
  ${desktopBreakpoint}{
    min-height: 75vh; 
    padding-bottom: 0; 
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${desktopBreakpoint}{
  
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: ${mobileVW(300)};
  z-index: ${zIndex.surface};

  h1{
    text-transform: uppercase;
    font-family: 'messina';
    font-weight: 300;
    line-height: 90%;
    font-size: ${mobileVW(80)};
    color: ${colors.black};
    ${desktopBreakpoint}{
      font-size: ${desktopVW(145)};  
    }
  }
  ${desktopBreakpoint}{
    padding-top: 0;
    padding-bottom: ${desktopVW(70)}; 
  }

`;


const Paragraph = styled.div`
  width: 80%;
  font-family: 'messinaMono';
  line-height: 140%;
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  text-align: center;
  padding-top: ${mobileVW(40)};
  z-index: ${zIndex.surface};

  ${desktopBreakpoint}{
    font-size: ${desktopVW(14.5)};
    width: 30%;
    padding-top: ${desktopVW(60)};
   
  }
`;

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;

`;

const TopRightImg = styled.div`
  position: absolute;
  top: ${mobileVW(-10)};
  width: ${mobileVW(180)};
  height: ${mobileVW(262)};
  right: ${mobileVW(30)};
  z-index: 90000;
  ${desktopBreakpoint}{
    top: 30%;
    right: ${desktopVW(140)};
    width: ${desktopVW(350)};
    height: ${desktopVW(200)};
    max-width: 350px;
    max-height: 200px;
  }
`;

const MidLeftImg = styled.div`
  position: absolute;
  top: ${mobileVW(60)};
  left: 0;
  height: ${mobileVW(202)};
  width: ${mobileVW(145)};

  ${desktopBreakpoint}{
    top: 20%;
    left: ${desktopVW(155)};
    width: ${desktopVW(340)};
    height: ${desktopVW(440)};
    max-width: 340px;
    max-height: 440px;
  }
`;

const BottomLeftImg = styled.div`
  position: absolute;
  bottom: ${mobileVW(30)};
  right: 0;
  width: ${mobileVW(370)};
  height: ${mobileVW(280)};
  z-index: 900000;


  ${desktopBreakpoint}{
    width: ${desktopVW(370)};
    height: ${desktopVW(280)};
    top: 65%;
    right: ${desktopVW(410)};
    width: ${desktopVW(200)};
    height: ${desktopVW(270)};
    max-width: 200px;
    max-height: 270px;
  }
`;