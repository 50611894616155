import React from 'react';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  desktopVW,
  mobileContentSidePadding,
  mobileVW,
  zIndex,
} from '../../styles/index';
import FeaturedItemThumbnail from '../shared/FeaturedItemThumbnail';

interface Props {
  heading?: string;
  subtitle?: string;
  paragraph?: string;
  items: {
    image?: {
      fluid: string;
      title: string;
    };
  };
}

export default function FeaturedItemSection(props: Props) {
  const { heading, subtitle, paragraph, items } = props;

  return (
    <Wrapper>
      <Text>
        <TextContent>
          {heading && <h5>{heading}</h5>}
          {subtitle && <h6>{subtitle}</h6>}
          {paragraph && <p>{paragraph}</p>}
        </TextContent>
      </Text>
      <GridWrapper>
        {items?.map((item, index) => (
          <ThumbnailWrapper key={item.title + index}>
            <FeaturedItemThumbnail
              thumbnailImage={item.image}
              title={item.title}
              invertedImages={index === 2}
              slug={item?.linkedPage?.slug}
              isFullScreen={index === 2}
              isMobileGrid="potato"
            />
          </ThumbnailWrapper>
        ))}
      </GridWrapper>
    </Wrapper>
  );
}

const GridWrapper = styled.div`
  display: grid;
  padding: ${mobileVW(35)} ${mobileVW(20)} 0 ${mobileVW(20)};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: ${mobileVW(20)};
  z-index: ${zIndex.surface};
  flex: 1;

  ${desktopBreakpoint} {
    grid-template-columns: repeat(2, ${desktopVW(308)});
    grid-template-rows: ${desktopVW(430)};
    grid-gap: ${desktopVW(26)};
    padding: 0 ${desktopVW(60)};
  }
`;

const ThumbnailWrapper = styled.div`
  height: ${mobileVW(269.65)};
  ${desktopBreakpoint} {
    height: ${desktopVW(420)};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${desktopBreakpoint} {
    flex-direction: row;
    padding: ${desktopVW(78)} ${desktopVW(10)} ${desktopVW(58)} ${desktopVW(0)};
  }
`;

const Text = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  z-index: ${zIndex.surface};
  display: flex;
  flex: 1;
  justify-content: center;
  padding: ${mobileContentSidePadding} ${desktopVW(144)};
  background-color: ${colors.white};
  ${desktopBreakpoint} {
    // width: 33.3vw;
    height: auto;
    padding: ${desktopVW(125)};
  }
`;

const TextContent = styled.div`
  position: relative;

  h5 {
    font-family: 'messinaMono';
    letter-spacing: -0.05em;
    font-size: ${mobileVW(55.2)};
    font-weight: 400;
    line-height: 90%;
    width: ${desktopVW(400)};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(80)};
      width: ${desktopVW(340)};
    }
  }

  h6 {
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    text-decoration: underline;
    font-weight: 300;
    font-size: ${mobileVW(23)};
    padding: ${mobileVW(30)} 0 0 0;

    ${desktopBreakpoint} {
      font-size: ${desktopVW(20)};
      padding: ${desktopVW(40)} 0 0 ${desktopVW(3)};
      width: 80%;
      line-height: 140%;
    }
  }

  p {
    font-family: 'messinaMonoLight';
    letter-spacing: -0.05em;
    font-weight: 300;
    color: ${colors.subtleGrey};
    padding: ${mobileVW(30)} 0 0 0;
    width: 100%;
    font-size: ${mobileVW(16)};

    ${desktopBreakpoint} {
      font-size: ${desktopVW(14.5)};
      padding: 0;
      width: 100%;
      line-height: ${desktopVW(21)};
      margin-top: ${desktopVW(53)};
    }
  }

  ${desktopBreakpoint} {
  }
`;
