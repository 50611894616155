import dot1 from './Vector-1.svg';
import dot2 from './Vector-2.svg';
import dot3 from './Vector-3.svg';
import dot4 from './Vector-4.svg';
import dot5 from './Vector-5.svg';
import dot6 from './Vector-6.svg';
import dot7 from './Vector-7.svg';
import dot8 from './Vector-8.svg';
import dot9 from './Vector-9.svg';
import dot10 from './Vector-10.svg';
import dot11 from './Vector-11.svg';
import dot12 from './Vector-12.svg';
import dot13 from './Vector-13.svg';
import dot14 from './Vector-14.svg';
import dot15 from './Vector-15.svg';
import dot16 from './Vector-16.svg';
import dot17 from './Vector-17.svg';
import dot18 from './Vector-18.svg';
import dot19 from './Vector-19.svg';
import dot20 from './Vector-20.svg';
import dot21 from './Vector-21.svg';
import dot22 from './Vector-22.svg';
import dot23 from './Vector-23.svg';
import dot24 from './Vector-24.svg';
import dot25 from './Vector-25.svg';
import dot26 from './Vector-26.svg';
import dot27 from './Vector-27.svg';
import dot28 from './Vector-28.svg';
import dot29 from './Vector-29.svg';


export default [
  dot1,
  dot2,
  dot3,
  dot4,
  dot5,
  dot6,
  dot7,
  dot8,
  dot9,
  dot10, 
  dot11, 
  dot12, 
  dot13, 
  dot14, 
  dot15, 
  dot16, 
  dot17, 
  dot18, 
  dot19, 
  dot20, 
  dot21, 
  dot22, 
  dot23, 
  dot24, 
  dot25, 
  dot26, 
  dot27, 
  dot28, 
  dot29, 

];